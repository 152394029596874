<template>
  <div class="base-rate">
    <!--    <div v-if="!isClicked && !state" class="standart-text base-rate__item note-color">-->
    <!--    <div v-if="rate.clientsetrate" class="standart-text base-rate__item note-color">-->
    <div v-if="rate && rate.clientsetrate" class="standart-text base-rate__item note-color">
      {{ text }}
      <plain-button
        icon="like"
        color="primary"
        size="small"
        class="base-rate__item-like"
        @click="setMode('like')"
      />
      <plain-button
        icon="dislike"
        color="del"
        size="small"
        class="base-rate__item-dislike"
        @click="setMode('dislike')"
      />
    </div>
    <!--    <div v-if="isClickedLike || isClickedDislike" class="standart-text base-rate__item note-color">-->
    <div v-if="rate && rate.client" class="standart-text base-rate__item note-color">
      <plain-button :icon="isLike" :color="color" size="small" class="base-rate__item" />
    </div>
  </div>
</template>

<script>
import store from '@/store';
import Vue from 'vue';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import OrderConfig from '@/components/Configurator/OrderConfig';

export default {
  name: 'BaseRate',
  components: {},
  mixins: [],
  props: {
    rate: {
      type: Object,
      default: () => {},
    },
    elid: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: '',
    },
    plid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      state: '',
      res: {
        success: 'success',
        fail: 'fail',
        cancel: 'cancel',
        back: 'back',
        next: 'next',
      },
      isClicked: '',
      configurator: {},
      isClickedLike: false,
      isClickedDislike: false,
    };
  },
  computed: {
    isLike() {
      if (this.isRated) return this.isRated.good ? 'like' : 'dislike';
      else return null;
    },
    tariff() {
      return this.$store.state.moduleSupport.quality;
    },
    // configurator() {
    //   console.log(this.tariff.configurator);
    //   return this.tariff.configurator;
    // },
    color() {
      if (this.isLike && this.isRated) return this.isLike === 'like' ? 'primary' : 'del';
      else return null;
    },
    isRated() {
      return this.rate.client;
    },
    profile() {
      return this.$store.state.moduleProfile.profile;
      // realname
      //  510540 - lastMessage
      //  108825 - ticket - plid
      // info_item_name
    },
    text() {
      return !this.isRated ? 'Оцените ответ' : null;
    },
    // clickedLike() {
    //   return this.isLike === 'like' ? (this.state = 'like') : (this.state = 'dislike');
    // },
  },
  watch: {
    tariff: {
      handler: function (event) {
        this.config(event, { ticket: this.plid, theme: this.theme });
      },
    },
  },
  mounted() {
    // this.init();
  },
  methods: {
    init() {
      return store.dispatch('moduleSupport/setQuality');
    },
    makeModal(name) {
      this.$modals.open({
        name: name,
        // name: 'Quality',
        size: 'big',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        // ...props,
      });
    },
    showLoadingModal() {
      if (!this.modal) this.makeModal('Quality');
      Vue.set(this.modal, 'text', null);
      // Vue.set(this.modal, 'title', this.$t('loading'));
      Vue.set(this.modal, 'component', BaseLoader);
      Vue.set(this.modal, 'closable', false);
      Vue.set(this.modal, 'footer', false);
    },
    showEditModal(props) {
      return new Promise(async (resolve, reject) => {
        this.showLoadingModal();
        await this.init();
        if (!this.modal) this.makeModal('Quality');
        let formData = {};
        // const title = 'Уточните вашу оценку.';
        let formDataBackup = null;
        // Vue.set(this.modal, 'title', title);
        Vue.set(this.modal, 'onDismiss', () => {
          this.$modals.close({ name: this.modal.name });
          setTimeout(() => this.showResModal('Вы отменили оценку.', 'Thanks'), 100);
          // this.showResModal('Спасибо за вашу оценку!', 'Thanks');
          // reject({ res: 'thanks' });
        });
        Vue.set(this.modal, 'props', props);
        Vue.set(this.modal, 'props', { period: 12, configurator: this.configurator });
        Vue.set(this.modal, 'text', null);
        Vue.set(this.modal, 'on', {
          init: data => {
            Object.assign(formData, data);
            formDataBackup = { ...formData };
            Vue.set(this.modal.footer.confirm.props, 'loading', false);
          },
          ready: data => {
            formData = this.getUpdatedFormData(formData, data);
            Vue.set(this.modal.footer.confirm.props, 'disabled', false);
          },
          notready: data => {
            formData = this.getUpdatedFormData(formData, data);
            Vue.set(this.modal.footer.confirm.props, 'disabled', true);
          },
          change: data => {
            // console.log('formData', formData);
            formData = this.getUpdatedFormData(formData, data);
            // console.log('data', data);
          },
        });
        Vue.set(this.modal, 'component', OrderConfig);
        Vue.set(this.modal, 'closable', true);
        Vue.set(this.modal, 'closeOnBackdrop', false);
        Vue.set(this.modal, 'footer', {
          confirm: {
            props: {
              title: 'Подтвердить',
              disabled: true,
              loading: false,
            },
            on: {
              click: () => {
                Vue.set(this.modal.footer.confirm.props, 'loading', true);
                // resolve({ data: formData, res: this.res.next });
                resolve({ data: formData, res: 'send' });
              },
            },
          },
          cancel: {
            props: {
              title: 'Отменить',
              loading: false,
            },
            on: {
              click: () => {
                // this.$modals.close({ name: this.modal.name });
                reject({ res: 'cancel' });
              },
            },
          },
        });
      });
    },
    config(data, payload) {
      const ticketTheme =
        data.slist.slist_user_items?.find(x => x.v.includes(payload.theme)) &&
        data.slist.slist_user_items?.find(x => x.v.includes(payload.theme)).k
          ? data.slist.slist_user_items?.find(x => x.v.includes(payload.theme)).k
          : 'NULL';
      this.tariff.slist_user_tickets = payload.ticket;
      this.configurator = this.tariff.makeConfigurator(
        data,
        this.tariff.quality,
        this.tariff.info,
        { ticket: payload.ticket, theme: ticketTheme }
      );
    },
    colorIcon(color) {
      return color === 'like' ? 'primary' : 'del';
    },
    getUpdatedFormData(formData, data) {
      let tData = { ...formData };
      Object.assign(tData, data);
      Object.keys(data).forEach(key => {
        if (data[key] == null && tData.hasOwnProperty(key)) {
          delete tData[key];
        }
      });
      return { ...tData };
    },
    async setMode(val) {
      val === 'like' ? (this.isClickedLike = true) : (this.isClickedDisike = true);
      let result = '';
      this.isClicked = val;
      // console.log('send: ' + val);
      await this.showEditModal({ like: val })
        .then(data => {
          result = data;
          // console.log(data);
          return this.$store.dispatch('moduleSupport/sendQuality', data.data);
        })
        .then(response => {
          // console.log(response);
          this.$modals.close();
          if (response && response.func && response.ok && response.ok === true) {
            this.showResModal('Спасибо за вашу оценку!', 'Thanks');
          } else
            this.showResModal(
              'Ошибка!\n' +
                'Форма отзыва временно не доступна по техническим причинам, но мы записали вашу оценку обращения.',
              'Thanks'
            );
        })
        .catch(e => {
          result = e;
          // console.log(e);
          if (e && e.res) {
            this.$modals.close();
            // console.log(e, e.res);
            this.showResModal('Вы отменили оценку.', 'Thanks');
          } else
            this.showResModal(
              'Ошибка!\n' +
                'Форма отзыва временно не доступна по техническим причинам, но мы записали вашу оценку обращения.',
              'Thanks'
            );
          // console.log(e);
          // if (e && e.res && e.res === 'cancel') {
          // }
        });
      // console.log(result.res);
      // /*
      if (result.res === 'send')
        this.sendBillMgrAction({
          func: this.rate.clientsetrate[val],
          auth: localStorage.getItem('token'),
          plid: this.plid,
          elid: this.elid,
        }).then(data => {
          // console.log(data);
          this.$emit('reactions', data);
        });
      // */

      // .then(data => {
      // if (data.ok === 'true') {
      // this.$store.dispatch('moduleSupport/fetchCurrentTicket', this.plid).then(data => {
      //   this.currentTicketInfo = data.model;
      // });
      // }
      // });
    },
    async sendBillMgrAction(params) {
      return store.dispatch('sendBillMgrToolAction', params);
    },
    showResModal(res, name) {
      // console.log('1111111111');
      if (!this.modal) this.makeModal(name);
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'size', 'small');
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        centered: true,
        confirm: {
          props: { title: this.$t('close') },
          on: {
            click: () => {
              this.$modals.close();
            },
          },
        },
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.base-rate {
  overflow : hidden;
  &__item {
    white-space: pre-wrap;
    &-like {
      margin-left: 1.5rem !important;
    }
    &-dislike {
      margin-left: 1.5rem !important;
    }
  }
}
</style>
