<template>
  <div :id="el" :class="classname" class="base-dialog">
    <div class="base-dialog-header">
      <div class="base-dialog-header-left-side">
        <div>
          <avatar :src="image" class="base-dialog-header-left-side__avatar" shape="circle" />
        </div>

        <div class="base-dialog-header-additional-info-block standart-text">
          <span v-if="name">{{ name }}</span>
          <span>{{ position }}</span>
        </div>
      </div>

      <div class="base-dialog-header-right-side standart-text">
        <span class="base-dialog-header-time">{{ time }}</span>
      </div>
    </div>
    <main-card>
      <div class="base-dialog-text">
        <base-message v-if="text" :message="text" />
      </div>
      <div v-if="files" class="base-dialog-file__container">
        <div v-for="(item, i) in files" :key="i" class="base-dialog__file-links">
          <svg-icon name="attach-with-text" size="small" class="base-dialog__file-icon" />
          <a :href="link(item)" target="_blank" class="base-dialog__file-link">
            <label class="small-text">
              {{ item.name }}
            </label>
          </a>
        </div>
      </div>
      <div v-if="!isArhive && showRate" class="base-dialog-rate">
        <base-rate
          v-if="type === 'incoming'"
          :rate="rate"
          :theme="theme"
          :elid="elid"
          :plid="plid"
          @reactions="onChange"
        />
      </div>
    </main-card>
  </div>
</template>

<script>
import BaseRate from '@/components/BaseChat/BaseRate';
import BaseMessage from '@/components/BaseChat/BaseMessage';
import Avatar from '@/components/Avatar/Avatar';
import MainCard from '@/components/MainCard/MainCard';

export default {
  name: 'BaseDialog',
  components: {
    BaseMessage,
    BaseRate,
    Avatar,
    MainCard,
  },
  props: {
    name: {
      type: String,
      default: 'Сотрудник поддержки',
    },
    theme: {
      type: String,
      default: '',
    },
    files: {
      type: Array,
      default: () => [],
    },
    // isArhive: {
    //   type: Boolean,
    //   default: false,
    // },
    position: {
      type: String,
      default: '',
    },
    el: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      default: '',
    },
    rate: {
      type: Object,
      default: () => {},
    },
    date: {
      type: Date,
      default: new Date(),
    },
    plid: {
      type: String,
      default: '',
    },
    elid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    classname() {
      return this.type === 'outcoming' ? 'chat__own' : 'chat__others';
    },
    differenceDate() {
      return Math.floor((new Date() - this.date) / (1000 * 60 * 60 * 24));
    },
    clientsetrate() {
      return this.rate?.client;
    },
    showLikeOrDislike() {
      return this.rate
        ? this.rate?.clientsetrate && this.differenceDate < 29 && this.type === 'incoming'
        : false;
    },
    showRate() {
      return this.showLikeOrDislike || this.clientsetrate;
    },
    isArhive() {
      return this.$route.path.includes('archive');
    },
    host() {
      return this.$store.state.moduleApp.host;
    },
  },
  methods: {
    // srcImg(item) {
    //   return `<a href="https://my.rusonyx.ru/billmgr?func=${item.action}&elid=${item.param.elid}">${item.name}</a>`;
    // },
    srcImg(item) {
      return `<a href="${this.host}/billmgr?func=${item.action}&elid=${item.param.elid}">${item.name}</a>`;
    },
    link(src) {
      return `${this.host}/billmgr?func=${src.action}&elid=${
        src.param.elid
      }&auth=${localStorage.getItem('token')}`;
    },
    onChange(value) {
      // console.log(value);
      this.$emit('update-rate', value);
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.radio-block__radio {
  margin-left: 1.2rem;
  //+breakpoint(xs-only) {
  +breakpoint(ms-and-down) {
  margin-left: 0.1rem;
  }
}
.base-dialog {
  //max-width: 545px;
  max-width: 600px;
  width: 100%
  margin-bottom: 20px;

  &:nth-child(1) {
    margin-left: 0;
    max-width: 925px;
    .base-dialog-header {
      display:none;
    }
  }

  &-rate {
    margin-top: 2rem;
  }


+breakpoint(xs-only) {
  margin-left: 0;
  max-width: 545px;
  width: 100%
}


&__file {
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-top: 20px;

  &-icon {
    margin-right: 10px
    display: flex;
    align-items: center;
    min-width: 20px;
    height: 20px;
    color: #ffffff;
    border-radius: 50%;
    //background-color: #84bbf6;
    background-size: auto;
    hyphens: auto;
    background-position: center;
  }

  &-links {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center
    margin-top: 1.5rem;
    //word-wrap: break-word;
    //flexy(flex-start, flex-start, nowrap, row);
    //align-items:center;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;

    }
  }

  &-link {
    //display: flex;
    max-width: 500px;
    align-items: center;
    text-decoration: none;
    place-content: center;
    //font-size: 12px;
    min-width: 35px;
    min-height: 36px;
    //color: $color-light.light-blue;
    color: $color-blue.light;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    z-index: 1;
    word-wrap: break-word
  &:hover {
    background: var(--main-card-bg);
    text-overflow: unset;
    overflow: visible;
    z-index: 1000;
  }
  }
}

.base-dialog-header {
  display: flex;
  flex-direction: row;
  max-width: 545px;
  margin-bottom: 20px;
  width: 100%

  +breakpoint(xs-only) {
    flex-direction: column;
  }

  .base-dialog-header-left-side {
    display: flex;
    width: 100%;

    &__avatar {
      size(60px);
      background: var(--avatar-bg);
      transition: background $anim-base-duration ease;
      cursor: pointer;
    }

    .base-dialog-header-additional-info-block {
      display: flex;
      white-space: nowrap
      flex-direction: column;
      justify-content: center;
      margin-left: 24px;
      width: 100%;

      +breakpoint(xs-only) {
        max-width: 250px;
      }
    }
  }

  .base-dialog-header-right-side {
    width: 100%;
    display: flex;

    .base-dialog-header-time {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      +breakpoint(xs-only) {
        margin-top: 15px;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }
  }
}

.base-dialog-file__content {
  padding-right: 10px;
}

}
.chat__others {
  width: 100%;
  max-width: 545px;

  +breakpoint(sm-only) {
    margin-left: 3.8rem;
  }

  +breakpoint(ms-only) {
    margin-left: 0;
  }
  +breakpoint(md-and-up) {
    margin-left: 6rem;
  }
}

.chat__own {
  max-width: 545px;
  width: 100%

  +breakpoint(sm-only) {
    margin-left: 10.9rem;
  }

  +breakpoint(ms-only) {
    margin-left: 0;
  }
  +breakpoint(md-and-up) {
    margin-left: 17.6rem;
  }
}
</style>
